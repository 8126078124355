<app-header></app-header>
<app-sidebar></app-sidebar>
<div id="main-content" *ngIf="videoAndBlogDetails != undefined">
  <div class="container-fluid">
    <div class="block-header top-head-vt">
      <div class="row">
        <div class="col-lg-5 col-md-12 col-sm-12">
          <ul class="breadcrumb vt-a">
            <li class="breadcrumb-item">
              <a [routerLink]="['/dashboard']">
                <i class="icon-home"></i>
              </a>
            </li>
            <li class="breadcrumb-item">
              <ng-container *ngIf="videoAndBlogDetails.type == 'Blog'">
                <a [routerLink]="['/blogs']">
                  Blogs
                </a>
              </ng-container>
              <ng-container *ngIf="videoAndBlogDetails.type == 'Video'">
                <a [routerLink]="['/videos']">
                  Videos
                </a>
              </ng-container>
            </li>
            <li class="breadcrumb-item">{{videoAndBlogDetails.type}} Details</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="blog-list">
      <div class="row align-items-start">
        <div class="col-lg-12 m-15px-tb">
          <article class="article">
            <div class="row align-items-start">
              <div class="col-lg-12 m-15px-tb">
                <div class="article-img">
                  <ng-container *ngIf="videoAndBlogDetails.type == 'Blog'">
                    <img src="{{videoAndBlogDetails.image_url}}" alt="blog-image">
                  </ng-container>
                  <ng-container *ngIf="videoAndBlogDetails.type == 'Video' && videoAndBlogDetails.video_url != ''">
                      <iframe width="100%" height="100%" frameborder="0" [src]="sanitizUrl(videoAndBlogDetails.video_url)"></iframe>
                  </ng-container>
                </div>
                <div class="article-title">
                  <h2>{{videoAndBlogDetails.title}}</h2>
                  <div class="article-content">
                    <p [innerHTML]="videoAndBlogDetails.description"></p>
                    <div class="share">
                        <h3>Share</h3>
                        <div class="share-icons">
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https://fambizcommunity.com/razzamataz-we-are-family/" target="_blank">
                                <img src="./../../../assets/assets/images/facebook.png" alt="Share on Facebook" class="facebook">
                            </a>      
                            <a href="https://twitter.com/intent/tweet?text=https://fambizcommunity.com/razzamataz-we-are-family/" target="_blank">
                                <img src="./../../../assets/assets/images/twitter.png" alt="Share on Twitter" class="twitter">
                            </a>
                            <a href="https://www.instagram.com/fambizcommunity/" target="_blank">
                              <img src="./../../../assets/assets/images/insta.jpg" alt="Share on insta" class="facebook">       
                          </a>
                        </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
